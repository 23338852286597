<template>
  <div
    v-scroll="onScroll"
    class="v-gallery"
  >
    <v-container
      :fluid="!isScrolling"
      class="transition-swing"
    >
      <v-row
        :no-gutters="!isScrolling"
        class="transition-swing mx-n4"
      >
        <v-col
          v-for="(pic, i) in paginatedPictures"
          :key="pic"
          cols="12"
          md="6"
          class="transition-swing"
        >
          <gallery-card
            :src="pic"
            @click.stop="setPicture(i)"
          />
        </v-col>
      </v-row>
    </v-container>
    <gallery-carousel
      :dialogOpen="dialog"
      :source="source"
      @close="close"
    />
  </div>
</template>

<script>

export default {
  name: 'Gallery',
  components: {
    GalleryCard: () => import('./GalleryCard'),
    GalleryCarousel: () => import('./GalleryCarousel')
  },
  props: ['source'],
  data: () => ({
    dialog: false,
    isScrolling: false,
    page: 1
  }),

  computed: {
    paginatedPictures () {
      return this.$store.state.gallery[this.source].slice(0, this.page * 12)
    }
  },

  methods: {
    onScroll () {
      this.isScrolling = window.pageYOffset > 50
    },
    setPicture (index) {
      this.$store.commit('gallery/SET_IMAGE_INDEX', index)
      this.$nextTick(() => (this.dialog = true))
    },
    close () {
      this.$nextTick(() => (this.dialog = false))
    }
  }
}
</script>

<style lang="sass">
  .gallery
    // max-width: 1280px
</style>
